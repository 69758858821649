<template>
    <div class="login_container">
        <div class="login_box">
            <!-- 头像区域 -->
            <div class="avatar_box">
                <img src="../../assets/logo_1024.png" alt/>
            </div>
            <!-- 登录表单区域 -->
            <el-form
                    ref="loginFormRef"
                    :model="loginForm"
                    :rules="loginFormRules"
                    label-width="0px"
                    class="login_form"
            >
                <!--用户名-->
                <el-form-item prop="username">
                    <el-input
                            v-model="loginForm.username"
                            placeholder="工号/邮箱"
                            suffix-icon="el-icon-user-solid"
                    ></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                    <el-input
                            v-model="loginForm.password"
                            placeholder="密码"
                            suffix-icon="el-icon-s-goods"
                            type="password"
                    ></el-input>
                </el-form-item>
                <!--按钮区域-->
                <el-form-item class="login__btns-wapper">
                    <el-button class="login__btn" type="primary" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
            <div class="login_hint">
                <el-checkbox class="reset_remember" v-model="loginForm.remember">记住密码</el-checkbox>
                <a class="reset_a" style="color: #1E90FF;" @click="goForgetPwd">?忘记密码</a>
            </div>
        </div>
    </div>
</template>

<script>
// import {login,login_by_wework} from "@/api/index.js";
import {login,getInfoNew,getLoginHomeAPI} from "@/api/login.js";

export default {
    data() {
        return {
            // 这是登录表单的数据绑定对象
            loginForm: {
                username: "",
                password: "",
                remember: false
            },
            // 这是表单的验证规则对象
            loginFormRules: {
                // 验证用户名是否合法
                username: [
                    {required: true, message: "请输入工号/邮箱", trigger: "blur"},
                    {
                        min: 5,
                        max: 30,
                        message: "长度在 5 到 30 个字符",
                        trigger: "blur",
                    },
                ],
                // 验证密码是否合法
                password: [
                    {required: true, message: "请输入密码", trigger: "blur"},
                    {
                        min: 6,
                        max: 20,
                        message: "长度在 6 到 20 个字符",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        // 在页面加载时从cookie获取登录信息
        let username = this.getCookie("username");
        let password = this.getCookie("password");
        // 如果存在赋值给表单，并且将记住密码勾选
        if(username){
            this.loginForm.username = username
            this.loginForm.password = password
            this.loginForm.remember = true
        }
        this.autoLoginInWework();
    },
    methods: {
        goForgetPwd(){
            this.$router.push('/forgetPwd')
        },
        // 获取cookie
        getCookie: function (key) {
            if (document.cookie.length > 0) {
                var start = document.cookie.indexOf(key + '=')
                if (start !== -1) {
                    start = start + key.length + 1
                    var end = document.cookie.indexOf(';', start)
                    if (end === -1) end = document.cookie.length
                    return unescape(document.cookie.substring(start, end))
                }
            }
            return ''
        },
        // 保存cookie
        setCookie: function (cName, value, expiredays) {
            var exdate = new Date()
            exdate.setDate(exdate.getDate() + expiredays)
            document.cookie = cName + '=' + decodeURIComponent(value) +
                    ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
        },
      async  getInfoList(){
            try {
                let res=await getInfoNew()
                if(res.code==0||res.code==200){
                    this.$store.commit("setUser", res.data);
                    this.$router.push("/home");
                    this.$message({
                        type: 'success',
                        message: '登录成功!'
                    });
                }else{
                    this.$message({
                            type: 'error',
                            message: `${res.message||'操作失败!'}`
                        });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    }); 
            }
        },
      async  login() {
            this.$refs.loginFormRef.validate(async (valid, obj) => {
                if (!valid) return;
                if(this.loginForm.remember){
                    this.setCookie("username",this.loginForm.username)
                    this.setCookie("password",this.loginForm.password)
                }else{
                    this.setCookie("username","")
                    this.setCookie("password","")
                }

                try {
                    const res = await login({
                        username: this.loginForm.username,
                        password: this.loginForm.password,
                        system:'HOME'
                    });
                    if(res.code===0||res.code===200){
                        // this.$router.push("/home");
                        this.$store.commit("setToken", res.data.access_token);
                        await this.getInfoList()
                    }else{
                        this.$message({
                            type: 'error',
                            message: `${res.message||'操作失败!'}`
                        });  
                    }
                } catch (error) {
                    this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    }); 
                }
               
            });
        },

        autoLoginInWework() {
            let ua = navigator.userAgent.toLowerCase();
            if(/wxwork/i.test(ua)) {
                let cookie = this.getCookie('WEWORK_LOGIN_TRY');
                if(cookie != 2) {
                    if(cookie == 1) {
                        this.setCookie('WEWORK_LOGIN_TRY',2,1/(24*60));//1min
                    } else {
                        this.setCookie('WEWORK_LOGIN_TRY',1,1/(24*60));//1min
                    }
                    let code = '';
                    let url = decodeURIComponent(location.href);
                    let str = url.match(/&code=(\S*)&state/);
                    //判断URL挂载参数
                    if (str == null) {
                        str = url.match(/\?code=(\S*)&state/);
                    }
                    if(str != null) {
                        code = str[1];
                    }
                    if(code != '') {
                        let params = {code:code};
                        getLoginHomeAPI(params).then(async(res) => {
                            this.setCookie('WEWORK_LOGIN_TRY', '', -1);
                            if (res.code === 0){
                                this.$store.commit("setToken", res.data.access_token);
                                // this.$store.commit("setUser", res.data);
                                // this.$router.push("/home");
                                await this.getInfoList()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: `${res.message || '企业微信授权登录失败，请手动登录'}`
                                }); 
                            }
                        }, (error) => {});

                    } else {
                        var CORPID = 'wx045d055c4e7bab5e';
                        var REDIRECT_URI = encodeURIComponent(location.href);
                        var auth_url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+CORPID+'&redirect_uri='+REDIRECT_URI+'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect';
                        location.href = auth_url;
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.login_container {
    background-color: $color-bg-gray;
    height: 100%;
}

.login_box {
    width: 420px;
    //height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px #ddd;

    .avatar_box {
        height: 90px;
        width: 90px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
}
.login_hint {
    margin: 10px 60px 40px 60px;
    box-sizing: border-box;
    overflow:hidden;
}
.login_form {
    margin: 70px 60px 10px 60px;
    box-sizing: border-box;
}

.el-form {
    display: block;
}

.login__btns-wapper {
    //display: flex;
    //justify-content: space-around;
    //margin: 25px 0;

    .login__btn {
        //margin: 0 50px;
        width: 100%;
    }
}
.reset_remember {
    float: left;
}
.reset_a {
    color: $color-a;
    font-size: $font-small;
    float: right;
}
.reset_a:hover{
    cursor: pointer;
}
</style>
